import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { ProductType } from '@.api/methods';
import { requiredRule, useDictionaries, useForm, ValidateOptions } from '@.hooks';
import { Layout } from '@.layout';
import { Input, LingualInput, Select, Upload } from '@.ui';
import { ProductData, ProductsActionProps } from '@/Components/Pages/Data/Products/Action/types';
import { validateOptions } from '@/Components/Pages/Data/Products/Action/validate';
import { Buttons } from '@/Components/Partials';
import { Auth, getLingualErrors, LinkHelper, Tools } from '@/Services';
import { DictionaryList } from '@/Types';

const AdminFields: FC<{ isAllowed?: boolean; children?: ReactNode; hidden?: boolean }> = ({ isAllowed, children, hidden }) => (
    <div className={'action-form' + (isAllowed ? '' : ' disabled') + (hidden ? ' hidden' : '')}>{children}</div>
);

export const ProductsAction: FC<ProductsActionProps> = (props) => {
    const auth = Auth.Use();
    const [product, setProduct] = useState<ProductType>();
    const [dictionaries, setDictionaries] = useDictionaries();
    const [field, form] = useForm<ProductData>();
    const navigator = useNavigate();
    const { id } = useParams();

    const handleCreate = () => {
        form.ifValid((data) => {
            Api.products()
                .create({ ...data, ages_ids: data.ages_ids ? data.ages_ids : [], tags_ids: data.tags_ids ? data.tags_ids : [] })
                .onSuccess(() => navigator(LinkHelper.dataProducts))
                .onValidationError((res) => form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') }));
        }, validateOptions);
    };

    const handleUpdate = () => {
        if (auth?.user?.is_super) {
            form.ifValid((data) => {
                Api.products()
                    .update({ ...data, ages_ids: data.ages_ids ? data.ages_ids : [], tags_ids: data.tags_ids ? data.tags_ids : [] }, Number(id))
                    .onSuccess(() => navigator(LinkHelper.dataProducts))
                    .onValidationError((res) => form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') }));
            }, validateOptions);

            return;
        }

        if (auth?.user?.permissions?.product_descriptions) {
            form.ifValid(
                (data) => {
                    const { description } = data;

                    if (!description) return;

                    const lingualData = Object.fromEntries(Object.entries(description).map(([lang, value]) => [`description_${lang}`, value]));

                    Api.products()
                        .description(Number(id), lingualData)
                        .onSuccess(() => navigator(LinkHelper.dataProducts))
                        .onValidationError((res) => {
                            form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') });
                        });
                },
                {
                    description: {
                        type: 'lingual',
                        required: requiredRule,
                        max: {
                            value: 1024,
                            message: 'Field must be up to 1024 characters',
                        },
                    },
                } as ValidateOptions<ProductData>
            );
        }
    };

    useEffect(() => {
        const dictionaries: DictionaryList = [
            'StoreGroups',
            'Brands',
            'ProductTypes',
            'Ages',
            'Sexes',
            'Strengths',
            'Occasions',
            'Ingredients',
            'Characters',
            'Tags',
        ];

        if (props.edit) {
            Api.products()
                .getById(Number(id))
                .onSuccess((res) => {
                    setProduct(res.data);
                    const {
                        udi,
                        name,
                        description,
                        company_description,
                        brand,
                        sexes,
                        ages,
                        characters,
                        ingredients,
                        occasions,
                        tags,
                        type,
                        strength,
                        images,
                    } = res.data;

                    const getDescription = () => {
                        if (auth?.user?.is_super) return description;

                        if (!company_description) return description;

                        return { ...Tools.object.filterNullable(description ?? {}), ...Tools.object.filterNullable(company_description) };
                    };

                    form.set.values({
                        udi,
                        name,
                        description: getDescription(),
                        brand_id: brand.id,
                        product_type_id: type.id,
                        strength_id: strength.id,
                        sexes_ids: sexes.map(({ id }) => id),
                        ages_ids: ages.map(({ id }) => id),
                        tags_ids: tags.map(({ id }) => id),
                        images_ids: images.map(({ id }) => id),
                        occasions_ids: occasions.map(({ id }) => id),
                        characters_ids: characters.map(({ id }) => id),
                        ingredients_ids: ingredients.map(({ id }) => id),
                    });
                });
        }

        setDictionaries(dictionaries);
    }, []);

    return (
        <Layout
            breadcrumbs={
                props.edit
                    ? [
                          { name: 'Products', link: LinkHelper.dataProducts },
                          { name: product?.name.en ?? '', link: LinkHelper.dataProductId(Number(id)) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'Products', link: LinkHelper.dataProducts },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            <h1>{props.edit ? 'Edit product' : 'Adding product'}</h1>
            <div className="action-form">
                <AdminFields isAllowed={props.permissions?.admin}>
                    <Input {...field('udi').register<string>()} text="UDI" required regex={/^[0-9]+$/} />
                    <LingualInput {...field('name').register()} text="Name" required />
                </AdminFields>
                <AdminFields isAllowed={true}>
                    <LingualInput {...field('description').register()} text="Description" textarea />
                </AdminFields>

                {/*<Select placeholder="Store groups" search multiple></Select>*/}
                <AdminFields isAllowed={props.permissions?.admin}>
                    <Select {...field('brand_id').register<number>()} placeholder="Brand" required>
                        {dictionaries?.brands?.map((brand) => (
                            <Select.Option key={`brand_${brand.id}`} id={brand.id}>
                                {brand.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('product_type_id').register<number>()} placeholder="Type" required>
                        {dictionaries?.product_types?.map((type) => (
                            <Select.Option key={`type_${type.id}`} id={type.id}>
                                {type.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('ages_ids').register<number[]>()} placeholder="Age" localSearch multiple>
                        {dictionaries?.ages?.map((age) => (
                            <Select.Option key={`age_${age.id}`} id={age.id}>
                                {age.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('sexes_ids').register<number[]>()} placeholder="Sex" multiple required>
                        {dictionaries?.sexes?.map((sex) => (
                            <Select.Option key={`sex_${sex.id}`} id={sex.id}>
                                {sex.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('strength_id').register<number>()} placeholder="Strength" required>
                        {dictionaries?.strengths?.map((strength) => (
                            <Select.Option key={`strength_${strength.id}`} id={strength.id}>
                                {strength.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('occasions_ids').register<number[]>()} placeholder="Occasion" multiple required>
                        {dictionaries?.occasions?.map((occasion) => (
                            <Select.Option key={`occasion_${occasion.id}`} id={occasion.id}>
                                {occasion.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('ingredients_ids').register<number[]>()} placeholder="Ingredients" localSearch multiple required>
                        {dictionaries?.ingredients?.map((ingredient) => (
                            <Select.Option key={`ingredient_${ingredient.id}`} id={ingredient.id}>
                                {ingredient.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('characters_ids').register<number[]>()} placeholder="Character" multiple required>
                        {dictionaries?.characters?.map((character) => (
                            <Select.Option key={`character_${character.id}`} id={character.id}>
                                {character.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select {...field('tags_ids').register<number[]>()} placeholder="Tags" localSearch multiple>
                        {dictionaries?.tags?.map((tag) => (
                            <Select.Option key={`tag_${tag.id}`} id={tag.id}>
                                {tag.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Upload.Image {...field('images_ids').register<number[]>()} value={product?.images} />
                </AdminFields>
                <Buttons text={props.edit ? 'Save' : 'Add'} onClickSave={props.edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
