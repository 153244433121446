import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { ProductType } from '@.api/methods';
import { Layout } from '@.layout';
import { Button } from '@.ui';
import { useId } from '@/Components/Hooks';
import { Auth, LinkHelper, Tools, useLingualData, useLingualField } from '@/Services';
import { PermissionProps } from '@/Types';

export const Product: React.FC<PermissionProps> = (props) => {
    const [product, setProduct] = useState<ProductType>();
    const navigator = useNavigate();
    const auth = Auth.Use();

    const updateProduct = (id: number) => {
        return Api.products()
            .getById(id)
            .onSuccess((res) => {
                setProduct(res.data);
            });
    };

    const id = useId(updateProduct, true);

    const handleDelete = () => {
        if (!id) return;

        Api.products()
            .delete(id)
            .onSuccess((res) => setProduct(res.data));
    };

    const handleRestore = () => {
        if (!id) return;

        Api.products()
            .restore(id)
            .onSuccess((res) => setProduct(res.data));
    };

    const productName = useLingualField(product);

    const descriptionData = useMemo(() => {
        if (!product) return {};

        const { description, company_description } = product;

        if (auth?.user?.is_super) return description ?? {};

        if (!company_description) return description ?? {};

        return { ...Tools.object.filterNullable(description ?? {}), ...Tools.object.filterNullable(company_description) };
    }, [product]);

    const productDescription = useLingualData(descriptionData);

    return (
        <Layout
            name="data"
            breadcrumbs={[
                { name: 'Products', link: LinkHelper.dataProducts },
                { name: productName, current: true },
            ]}
        >
            {product && (
                <>
                    <h1>
                        {productName}
                        <div>
                            <Button
                                color="transparent"
                                text="Prices"
                                onClick={() => navigator(LinkHelper.dataProductPricesId(Number(id)))}
                                disabled={!props.permissions?.update || auth?.user?.is_super}
                            />
                            <Button
                                color="transparent"
                                text={product?.deleted_at ? 'Restore' : 'Archive'}
                                onClick={product?.deleted_at ? handleRestore : handleDelete}
                                disabled={!props.permissions?.delete}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.dataProductEditId(Number(id)))}
                                disabled={!!product.deleted_at || !props.permissions?.update}
                            />
                        </div>
                    </h1>
                    <div className="entity-page">
                        <div className="entity-page__container">
                            <div>
                                <div className="entity-page__item">
                                    <div className="bold">UDI</div>
                                    <div>{product?.udi}</div>
                                </div>
                                <div className="entity-page__item">
                                    <div className="bold">Adding day</div>
                                    <div>{product.created_at ? Tools.date.autoFormat(product.created_at * 1000) : ''}</div>
                                </div>
                                <div className="entity-page__item">
                                    <div className="bold">Name</div>
                                    <div>{productName}</div>
                                </div>
                                <div className="entity-page__item">
                                    <div className="bold">Description</div>
                                    <div>{productDescription}</div>
                                </div>
                            </div>
                            <img src={product?.images?.[0]?.url} alt={product?.images?.[0]?.name} />
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Companies</div>
                            <div>{product?.companies.map((company) => company.name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Brand</div>
                            <div>{product?.brand.name}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Type</div>
                            <div>{product?.type.name}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Age</div>
                            <div>{product?.ages.map(({ name }) => name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Sex</div>
                            <div>{product?.sexes.map(({ name }) => name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Strength</div>
                            <div>{product?.strength.name}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Occasion</div>
                            <div>{product?.occasions.map(({ name }) => name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Ingredients</div>
                            <div>{product?.ingredients.map(({ name }) => name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Characters</div>
                            <div>{product?.characters.map(({ name }) => name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Tags</div>
                            <div>{product?.tags.map(({ name }) => name).join(', ')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Number of sales</div>
                            <div>{product?.sales_count}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Whiffs</div>
                            <div>{product?.whiffs_count}</div>
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
