import useModal from '@fipnooone/react-modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api, Code } from '@.api';
import { StationResponseType } from '@.api/methods';
import { useId, useTabs } from '@.hooks';
import { Layout } from '@.layout';
import { Board, Filters } from '@.partials';
import { Auth, LinkHelper } from '@.services';
import { Button, Filter3, FilterData } from '@.ui';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { PermissionProps } from '@/Types';

import { Firmware } from './tabs/firmware';
import { General } from './tabs/general';
import { Logs } from './tabs/logs';
import { Products } from './tabs/products';
import { Statistics } from './tabs/statistics';

export const Station: React.FC<PermissionProps> = (props) => {
    const [station, setStation] = useState<StationResponseType>();
    const [filterData, setFilters] = useState<FilterData>();
    const [modalNode, modal] = useModal();
    const auth = Auth.Use();
    const navigate = useNavigate();

    const get = (id: number) => {
        return Api.stations()
            .getById(id)
            .onSuccess((res) => setStation(res.data));
    };

    const stationId = useMemo(() => station?.id, [station?.id]);

    const handleDestroy = useCallback(() => {
        if (!stationId) return;

        Api.stations()
            .destroy(stationId)
            .onSuccess(() => {
                navigate(LinkHelper.stations);
            });
    }, [stationId]);

    const handleDestroyConfirm = useCallback(() => {
        modal.set(<ModalConfirm message="Do you really want to permanently delete this station?" onConfirm={handleDestroy} />);
    }, [handleDestroy]);

    const handleDelete = useCallback(() => {
        if (!stationId) return;

        Api.stations()
            .delete(stationId)
            ?.then((res) => {
                switch (res?.code) {
                    case Code.Success:
                        Api.stations()
                            .getById(stationId)
                            .onSuccess((res) => setStation(res.data));
                }
            });
    }, [stationId]);

    const handleRestore = useCallback(() => {
        if (!stationId) return;

        Api.stations()
            .restore(stationId)
            ?.then((res) => {
                switch (res?.code) {
                    case Code.Success:
                        Api.stations()
                            .getById(stationId)
                            .onSuccess((res) => setStation(res.data));
                }
            });
    }, [stationId]);

    useId(get);

    const handleFilterChange = (data: FilterData) => setFilters(data);

    const [controls, [filters, content], path] = useTabs<[React.ReactNode, React.ReactNode]>([
        {
            name: 'General',
            path: 'general',
            is_default: true,
            content: [null, <General key={station?.id} station={station} />],
        },
        {
            name: 'Devices',
            path: 'devices',
            content: [null, <Firmware key={station?.id} station={station} />],
        },
        {
            name: 'Statistic',
            path: 'statistic',
            content: [
                <Filter3 key={station?.id} onChange={handleFilterChange}>
                    <Filter3.Date switcher />
                </Filter3>,
                <Statistics key={station?.id} station={station} filters={filterData} />,
            ],
        },
        {
            name: 'Products',
            path: 'products',
            content: [
                <Filters key={station?.id} onChange={handleFilterChange} />,
                <Products key={station?.id} station={station} filters={filterData} permissions={props.permissions} />,
            ],
        },
        {
            name: 'Logs',
            path: 'logs',
            content: [
                <Filters key={station?.id} filters={['StationLogTypes']} onChange={handleFilterChange} date="full" />,
                <Logs key={station?.id} station={station} filters={filterData} />,
            ],
        },
    ]);

    useEffect(() => {
        setFilters(undefined);
    }, [path]);

    return (
        <Layout
            nobg
            name="station"
            breadcrumbs={[
                { name: 'Stations', link: LinkHelper.stations },
                { name: station?.name ?? '', current: true },
            ]}
        >
            {modalNode}
            {station && (
                <Board className="station">
                    <Board.Item head>
                        <div className="station__header">
                            <h1>
                                {station.name}
                                <div>
                                    {/*<Button color="brown" text="Block" />*/}
                                    {auth?.user?.is_super && <Button color="transparent" text={'Permanently delete'} onClick={handleDestroyConfirm} />}
                                    <Button
                                        color="brown"
                                        text={station?.deleted_at ? 'Restore' : 'Archive'}
                                        onClick={station?.deleted_at ? handleRestore : handleDelete}
                                        disabled={!props.permissions?.delete}
                                    />
                                    <Button
                                        color="brown"
                                        text="Edit"
                                        onClick={() => navigate(LinkHelper.stationsEditId(station?.id))}
                                        disabled={!!station?.deleted_at || !props.permissions?.update}
                                    />
                                </div>
                            </h1>
                        </div>

                        {controls}

                        <div className="station__filters">{filters}</div>
                    </Board.Item>
                    {content}
                </Board>
            )}
        </Layout>
    );
};
