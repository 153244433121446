import cn from 'classnames';
import React, { useEffect } from 'react';

import { useTrustedState } from '@.hooks';
import { Icon } from '@.ui';

import { CheckboxProps } from './types';

export const Checkbox: React.FC<CheckboxProps> = ({ isDisabled, onChange, onClick, text, value }) => {
    const [checked, setChecked, isTrusted] = useTrustedState<boolean>(false);

    const handleClick = (event: React.MouseEvent) => {
        if (onClick) {
            const res = onClick(event);

            if (typeof res === 'object')
                res.onErrors(() => {
                    setChecked(false);
                });
        }

        if (!isDisabled && event.isDefaultPrevented() === false) {
            setChecked((prev) => !prev, true);
        }

        // if (props.onChange) props.onChange({ target: true });
    };

    useEffect(() => {
        if (value === undefined) {
            return;
        }

        setChecked(value, false);
    }, [value]);

    useEffect(() => {
        if (!onChange || !isTrusted) {
            return;
        }

        onChange(checked);
    }, [checked]);

    return (
        <button className={cn('checkbox', { disabled: isDisabled })} onClick={handleClick} type="button">
            <div className={cn('checkbox__block', { checked })}>
                <Icon name="checkmark" size={20} />
            </div>
            {text && <p>{text}</p>}
        </button>
    );
};
