import { useEffect, useState } from 'react';

import { Board, Table, TableColumn, TableRow } from '@/Components/Partials';
import { Checkbox } from '@/Components/UI';
import { Tools } from '@/Services';
import { Api } from '@/Services/api';
import { Language } from '@/Services/api/methods';

import { StationTab } from '../types';

export const General: StationTab = ({ station = null }) => {
    const [languages, setLanguages] = useState<Language[]>([]);

    useEffect(() => {
        Api.languages()
            .getAll()
            .onSuccess(({ data }) => {
                setLanguages(data.languages);
            });
    }, []);

    return (
        station && (
            <Board.Item>
                <Table>
                    <TableRow>
                        <TableColumn bold>Identifier</TableColumn>
                        <TableColumn>{station?.identifier}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Name</TableColumn>
                        <TableColumn>{station?.name}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Store</TableColumn>
                        <TableColumn>{station?.store && station.store.name}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Number of connected iRoma devices</TableColumn>
                        <TableColumn>{station?.devices_count || '0'}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Move intervals between chambers, ms</TableColumn>
                        <TableColumn>{station?.move_interval_between_chambers}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Offline, s</TableColumn>
                        <TableColumn>{station?.offline_mode}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Min whiff interval (T1), s</TableColumn>
                        <TableColumn>{station?.min_whiff_interval_t1}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Min whiff interval (T2), s</TableColumn>
                        <TableColumn>{station?.min_whiff_interval_t2}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>PIN code</TableColumn>
                        <TableColumn>{station?.pin}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Languages</TableColumn>
                        <TableColumn>
                            {station?.languages
                                .map((code) => languages.find((language) => language.code === code)?.name)
                                .filter((item) => item !== undefined)
                                .join(', ') ?? ''}
                        </TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Currency</TableColumn>
                        <TableColumn>{station?.currency?.name ?? ''}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Last online</TableColumn>
                        <TableColumn>{station?.last_online_at ? Tools.date.autoFormat(station.last_online_at * 1000) : ''}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Inactive time</TableColumn>
                        <TableColumn>{station?.inactive_time ? Tools.time.format(station.inactive_time) : ''}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Brand strict dependency</TableColumn>
                        <TableColumn>
                            <Checkbox value={station?.is_brand_strict} isDisabled />
                        </TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Show price range</TableColumn>
                        <TableColumn>
                            <Checkbox value={station?.show_price_range} isDisabled />
                        </TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Update</TableColumn>
                        <TableColumn>
                            <Checkbox value={station?.is_updated} isDisabled />
                        </TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn bold>Archived at</TableColumn>
                        <TableColumn>{station?.deleted_at ? Tools.date.autoFormat(station?.deleted_at * 1000) : ''}</TableColumn>
                    </TableRow>
                </Table>
            </Board.Item>
        )
    );
};
