import { Pages, PagesForReadOnlyUser } from '@/Routes';
import { Routes } from '@/Routes/types';
import { User } from '@/Services/Auth/types';
import { UserRoleEnum } from '@/Types/api/User';

export const pagesByUser = (user?: User): Routes => {
    if (user?.role?.key === UserRoleEnum.SERVICE_ENGINEER) {
        return PagesForReadOnlyUser;
    }

    return Pages;
};
