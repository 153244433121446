import cn from 'classnames';
import React from 'react';

import { Header, Sidebar, WidgetChat } from '@.partials';
import { Breadcrumb } from '@.ui';

type LayoutProps = {
    children?: React.ReactNode;
    name?: string;
    breadcrumbs?: (Breadcrumb | undefined)[];
    small?: boolean;
    medium?: boolean;
    nobg?: boolean;
    className?: string;
};

/**
 * TODO: use outlet? Instead in every page component add layout?
 */
export const Layout: React.FC<LayoutProps> = ({ children, name, breadcrumbs, small, medium, nobg }) => {
    return (
        <>
            <div className={cn('page', name)}>
                <Sidebar />
                <div className="page__container">
                    <Header breadcrumbs={breadcrumbs?.filter((item) => item !== undefined) as Breadcrumb[]} />
                    <div className={cn('page__content', { small }, { medium }, { nobg })}>{children}</div>
                </div>
                <WidgetChat />
            </div>
        </>
    );
};
