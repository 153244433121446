import { useMemo, useState } from 'react';

import { useDictionaries, useForm, useObjectEffect } from '@.hooks';
import { DatePicker, Select } from '@.ui';
import { Switch, Switcher } from '@/Components/UI/Switcher';
import { Tools } from '@/Services';
import { AllDictionaries, CustomDictionary, Dictionary } from '@/Types';

import S from './styles.module.scss';
import { DictionaryFilter, FilterData, FiltersProps } from './types';

export const Filters: React.FC<FiltersProps> = ({ filters, onChange, search = true, date, fromData }) => {
    const [dictionaries, setDictionaries, setDictionaryData] = useDictionaries();
    const [field, form] = useForm<FilterData>();
    const [dateRange, setDateRange] = useState<[number | undefined, number | undefined]>([undefined, undefined]);
    const [offset, setOffset] = useState<Switch>();

    useObjectEffect(() => {
        if (!filters) return;

        if (fromData === undefined) {
            setDictionaries(
                filters.filter(
                    (filter) => CustomDictionary[filter as keyof typeof CustomDictionary] || Dictionary[filter as keyof typeof Dictionary]
                ) as AllDictionaries
            );
            return;
        }

        if (fromData) setDictionaryData(fromData);
    }, [filters, fromData]);

    useObjectEffect(() => {
        const state: { [name: string]: string | number | number[] } = Object.fromEntries(
            Object.entries(form.state)
                .map(([key, filter]) => {
                    const value = () => {
                        if (!filter || typeof filter === 'string' || key === 'date') return undefined;

                        switch (typeof filter.value) {
                            case 'string':
                                return filter.value;
                            case 'number':
                                return filter.value === -1 ? undefined : filter.value;
                            case 'object':
                                return filter.value.includes(-1) ? undefined : filter.value; //filter.value.map((val) => `${key}[]=${val}`);
                            default:
                                return undefined;
                        }
                    };

                    return [key, value()];
                })
                .filter((item) => item[1] !== undefined || item[1] !== '')
        );

        if (onChange) {
            const query = Object.fromEntries(
                Object.entries(form.state)
                    .map(([key, filter]) => {
                        const getValue = () => {
                            if (!filter || typeof filter === 'string') return null;

                            switch (typeof filter.value) {
                                case 'string':
                                    return `${filter.value}`;
                                case 'number':
                                    return `${filter.value}`;
                                case 'object':
                                    return filter.value.length ? `${filter.value.join(',')}` : null; //filter.value.map((val) => `${key}[]=${val}`);
                                default:
                                    return null;
                            }
                        };

                        return [key, getValue()];
                    })
                    .filter((item) => item[1] !== null || item[1] !== '')
            );

            const [from, to] = dateRange;

            if (from) query.start_date = from / 1000;
            if (to) query.end_date = to / 1000;

            onChange(query);
        }

        form.set.values(state);
    }, [form.state, dateRange]);

    const handleSearch = (input: string | undefined) => field('search').value(input);

    const handleDatePick = (range?: [number | undefined, number | undefined]) => {
        const [from, to] = range ?? [undefined, undefined];

        if (!from && !to) setOffset(Switch.Month);

        setDateRange([from, to]);
        setOffset(undefined);
    };

    const handleOffsetPick = (offset?: Switch) => {
        if (!offset) {
            setDateRange([undefined, undefined]);
            return;
        }

        setDateRange(() => {
            switch (offset) {
                case Switch.Day:
                    return [Tools.date.create().dayStart().timestamp(), Tools.date.create().dayEnd().timestamp()];
                case Switch.Month: {
                    const currentDate = Tools.date.create();

                    return [
                        Tools.date.create(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1)).dayStart().timestamp(),
                        Tools.date.create(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.lastDay())).dayEnd().timestamp(),
                    ];
                }
                case Switch.Year: {
                    const currentYear = new Date().getUTCFullYear();

                    return [
                        Tools.date.create(Date.UTC(currentYear, 0, 1)).dayStart().timestamp(),
                        Tools.date
                            .create(Date.UTC(currentYear, 11, Tools.date.create(Date.UTC(currentYear, 11, 1)).lastDay()))
                            .dayEnd()
                            .timestamp(),
                    ];
                }
            }
        });
    };

    const dateContent = useMemo(() => {
        switch (date) {
            case true:
                return <DatePicker value={dateRange} onChange={handleDatePick} />;
            case 'date&switcher':
                return (
                    <>
                        <Switcher value={offset} onChange={handleOffsetPick} />
                        <DatePicker value={dateRange} onChange={handleDatePick} />
                    </>
                );
            case 'full':
                return <DatePicker value={dateRange} onChange={handleDatePick} full />;
            default:
                return null;
        }
    }, [date, dateRange, offset]);

    return (
        <div className={S.Filters}>
            {search && <Select placeholder="Search" onSearch={handleSearch} absolute search />}
            {dictionaries &&
                filters &&
                filters.map((filterName) => {
                    const [name0, name1] = [Dictionary[filterName as keyof typeof Dictionary], CustomDictionary[filterName as keyof typeof CustomDictionary]];

                    const filter = DictionaryFilter[name0] ?? DictionaryFilter[name1];

                    if (!filter) return null;

                    const dictionary = dictionaries[name0] ?? dictionaries[name1];

                    if (!dictionary) return null;

                    switch (filter.type) {
                        case 'number':
                        case 'number[]':
                            return (
                                <Select
                                    {...(filter.type === 'number[]'
                                        ? { ...field(filter.name).register<number[]>(), multiple: true }
                                        : { ...field(filter.name).register<number>() })}
                                    key={`Filters_${filter.name}`}
                                    placeholder={filter.visibleName}
                                    label={filter.visibleName}
                                    absolute
                                    theme="white"
                                    hideList
                                >
                                    <Select.Option id={-1}>{'All'}</Select.Option>
                                    {dictionary.map((item) => (
                                        <Select.Option key={`Filters_Option_${item.id}`} id={item.id}>
                                            {item.name.toString()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            );
                        default:
                            return null;
                    }
                })}
            {dateContent}
        </div>
    );
};

export type { FilterData };
