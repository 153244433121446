import React, { useMemo, useState } from 'react';

import { BaseInput, LanguageSelectBase } from '@.ui';
import { useObjectEffect, useTrustedState } from '@/Components/Hooks';
import { Language } from '@/Config';
import { useLanguage } from '@/Services';

import { LingualData, LingualInputProps } from './types';

const getValue = (code: string, data: LingualData) => data[code] ?? data[`name_${code}`];

export const LingualInput: React.FC<LingualInputProps> = ({ errors, onChange, text, type, value, name = 'name', required, textarea, onlyEn }) => {
    const [initial] = useLanguage();
    const [data, setData] = useTrustedState(value);
    const [selected, setSelected] = useState<string>();

    const current = useMemo(() => {
        const lang = Language.languagesList.find((lang) => lang.code === (onlyEn ? 'en' : selected)) ?? initial;

        if (!lang) return { language: undefined, value: '' };

        const value = (data && getValue(lang.code, data)) ?? '';

        return { language: lang, value };
    }, [selected, data]);

    useObjectEffect(() => {
        if (!initial) return;

        setSelected((prev) => {
            if (onlyEn) return 'en';
            if (!prev) return initial.code;
            return prev;
        });
    }, [initial]);

    useObjectEffect(() => {
        if (!value) return;

        const keys = Object.keys(value);

        if (keys.length && keys.some((k) => k.match(`${name}_`))) return;

        setData(value);
    }, [value]);

    useObjectEffect(() => {
        onChange && onChange(data);
    }, [data]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!selected) return;

        setData((prev) => ({ ...prev, [selected]: event.target.value }));
    };

    return (
        <BaseInput className={'multi-lang-input' + (errors && errors.length ? ' error' : '') + (textarea ? ' textarea' : '') + (onlyEn ? ' full' : '')}>
            <div className="multi-lang-input__input-block">
                {(text || (errors && errors.length)) && (
                    <label className="input-block__label">
                        {errors && errors.length ? errors[0] : text}
                        {required && <span>*</span>}
                    </label>
                )}
                {/* <input value={current.value} onChange={handleChange} /> */}
                {textarea ? <textarea value={current.value} onChange={handleChange} /> : <input value={current.value} onChange={handleChange} />}
            </div>
            {!onlyEn && <LanguageSelectBase onChange={setSelected} current={current.language} key="lingual-input-select" />}
        </BaseInput>
    );
};
