import { AnalyticsRoutes } from './analytics';
import { AuthPages } from './auth';
import { ClientsPages } from './clients';
import { DashboardRoutes } from './dashboard';
import { DataPages } from './data';
import { Devices } from './devices';
import { LogsPages } from './logs';
import { MarketplaceRoutes } from './marketplace';
import { MyPageRoutes } from './myPage';
import { NotFoundPages } from './notFound';
import { OrdersPages } from './orders';
import { Redirect } from './redirect';
import { SettingsPages } from './settings';
import { StationPages } from './stations';
import { Routes } from './types';

/**
 * TODO: all component replace on lazy(() => import('path/to/component/'));
 */
export const Pages: Routes = [
    ...AuthPages,
    ...MyPageRoutes,
    ...DashboardRoutes,
    ...AnalyticsRoutes,
    ...MarketplaceRoutes,
    ...NotFoundPages,
    ...StationPages,
    ...ClientsPages,
    ...OrdersPages,
    ...DataPages,
    ...LogsPages,
    ...SettingsPages,
    ...Redirect,
    ...Devices,
];

export const PagesForReadOnlyUser: Routes = [...AuthPages, ...MyPageRoutes, ...StationPages, ...Redirect];
