import { RoutePage } from '../types';
import { BrandsPage } from './brands';
import { CurrenciesPage } from './currencies';
import { FilesPage } from './files';
import { IngredientsPage } from './ingredients';
import { PackagesUpdatePage } from './packagesUpdate';
import { ProductsPage } from './products';
import { ProductTypesPage } from './productTypes';
import { SpecialOffersPage } from './specialOffers';

export const DataPages: RoutePage[] = [
    {
        url: 'data',
        permissions: 'readOnly',
        sidebar: {
            icon: 'data',
            name: 'Data',
        },
        children: [FilesPage, PackagesUpdatePage, BrandsPage, IngredientsPage, CurrenciesPage, ProductTypesPage, ProductsPage, SpecialOffersPage],
    },
];
