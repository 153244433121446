import { FC } from 'react';

import { Tools } from '@/Services';
import { Dictionary } from '@/Types';

import { type FilterData, Filters as Component } from './modules';
import { DictionaryProps, FiltersComponent } from './types';

export const Filters: FiltersComponent = Component as FiltersComponent;

export { type FilterData };

const names = {
    [Dictionary.Users]: 'user_id',
    [Dictionary.Roles]: 'role_id',
    [Dictionary.Companies]: 'company_id',
    [Dictionary.StoreGroups]: 'store_group_id',
    [Dictionary.Stores]: 'store_id',
    [Dictionary.Brands]: 'brand_id',
    [Dictionary.ProductTypes]: 'type_id',
    [Dictionary.Ages]: 'age_id',
    [Dictionary.Sexes]: 'sex_id',
    [Dictionary.Strengths]: 'strength_id',
    [Dictionary.Occasions]: 'occasion_id',
    [Dictionary.Ingredients]: 'ingredient_id',
    [Dictionary.Characters]: 'character_id',
    [Dictionary.Tags]: 'tag_id',
    [Dictionary.Stations]: 'station_id',
    [Dictionary.Currencies]: 'currency_id',
    [Dictionary.WizardSteps]: 'step',
    [Dictionary.ProductUdis]: 'udi',

    [Dictionary.DeviceStatuses]: 'status',
    [Dictionary.ProductOrderStatuses]: 'status',
    [Dictionary.DeviceOrderStatuses]: 'status',
    [Dictionary.OrderStatuses]: 'status',
    [Dictionary.UserStatuses]: 'status',
    [Dictionary.CompanyStatuses]: 'status',
    [Dictionary.StoreGroupStatuses]: 'status',
    [Dictionary.StoreStatuses]: 'status',
    [Dictionary.StationStatuses]: 'status',
    [Dictionary.StationLogTypes]: 'station_log_types',
    [Dictionary.WLMFirmwareManagementFiles]: 'wlm_firmware_management_file',
    [Dictionary.LMPFirmwareManagementFiles]: 'lmp_firmware_management_file',

    [Dictionary.DeviceLogTypes]: 'type',
    [Dictionary.ManagementFileTypes]: 'type',
    [Dictionary.SpecialOfferTypes]: 'types',
    [Dictionary.SystemLogTypes]: 'type',

    [Dictionary.FavoriteHeart]: 'limit',
};

Object.keys(Dictionary).forEach((key) => {
    // if (typeof key !== 'string') return;

    const dictionary = key as keyof typeof Dictionary;

    const name = names[Dictionary[dictionary]];
    const visibleName = Tools.string.uppercaseFirstLetter(name.replaceAll(/_id(s)?/gi, '').replaceAll('_', ' '));

    const filter: FC<DictionaryProps> = () => null;

    filter.defaultProps = {
        isFilter: true,
        name,
        visibleName,
        dictionary,
    };

    Filters[dictionary] = filter;
});

Filters.Search = () => null;

Filters.Search.defaultProps = {
    isSearchFilter: true,
};

Filters.Date = () => null;

Filters.Date.defaultProps = {
    isDateFilter: true,
};

Filters.Custom = () => null;

Filters.Custom.defaultProps = {
    isCustomFilter: true,
};

Filters.Boolean = () => null;

Filters.Boolean.defaultProps = {
    isBooleanFilter: true,
};
// search.defaultProps = {
//     absolute: true,
//     search: true,
//     isSearchFilter: true,
// } as SearchFilterProps;

// const date = DateFilter;

// date.defaultProps = {
//     isDateFilter: true,
// };

export { names as DictionaryNames };
