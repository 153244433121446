import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateFilter } from '@/Components/UI/Filters.v3/modules';
import { DateRangeValue } from '@/Components/UI/Filters.v3/modules/date/types';
import { Switch } from '@/Components/UI/Switcher';
import { isNumber } from '@/Lib';
import { analyticsStoreSelectors } from '@/Store';

import S from './styles.module.scss';

export const AnalyticsPageDateFilter: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const updatePageFilters = analyticsStoreSelectors.use.updatePageFilters();

    const onChangeDateFilter = (date: DateRangeValue) => {
        /**
         * TODO: to utils maybe.
         */
        const fromDate = Math.floor((date[0] ?? 0) / 1000);
        const toDate = Math.floor((date[1] ?? 0) / 1000);

        if (isNumber(fromDate) && isNumber(toDate)) {
            setSearchParams(searchParams);

            updatePageFilters({ filters: { from: fromDate, to: toDate } });
        }
    };

    return (
        <div className={S.analytics_page_date_filter}>
            <DateFilter onChange={onChangeDateFilter} switcher initialSwitchValue={Switch.Month} />
        </div>
    );
};
